import { get, post } from '@utils/Axios'
import { useRoute } from 'vue-router'

const getTickets = () => {
    return get( '/support', {} )
        .then( ( res ) => { return res.data } )
}


const joinSession = ( ticket_id ) => {
    return get( '/support/' + ticket_id , {} )
        .then( ( res ) => {
            return res.data
        } )
        .catch( ( err ) => {
            // TODO:: handle error when fetching session info fails
        } )
}


const sendToServer = ( ticket_id, message ) => {
    return post( '/support/' + ticket_id + '/message', message )
        .then( ( res ) => {
            return res.data
        } )
}

export { getTickets, joinSession, sendToServer }

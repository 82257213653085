
import { ref, getCurrentInstance, reactive, onMounted } from "vue"
import { getTickets } from "@requests/Support"
import { Ticket } from "@models/Ticket"
import Layout from "@components/Layout.vue"

export default {
    name: "Support",
    components : { Layout },
    setup(){
        const tickets = ref< Ticket[] >( [] )

        // @ts-ignore
        const { proxy } = getCurrentInstance()

        onMounted( async () => {
            tickets.value = await getTickets()
        })

        return { proxy, tickets }
    },
}
